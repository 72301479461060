// import Footer from "components/footer/footer.component";
import Header from "components/header/header.component";
import Orcamento from "components/orcamento/orcamento.component";
import Portfolio from "components/portfolio/portfolio.component";
import QuemSomos from "components/quem-somos/quem-somos.component";
import Servicos from "components/servicos/servicos.component";
import Footer from "components/footer/footer.component";
import React from "react";

import "./Home.scss";

const App = () => (
  <div className="App">
    <Header />
    <QuemSomos />
    <Servicos />
    <Portfolio />
    <Orcamento />
    <Footer />
  </div>
);

export default App;

import { Col, Row } from "antd";
import React from "react";

import "./section.styles.scss";

const Section = ({ title, children, backgroundColor = "#f4f4f4" }) => (
  <Row className="section-container" justify="center" style={{ backgroundColor }}>
    <Col span={24}>
      <p className="section-title">{title}</p>
    </Col>
    {children}
  </Row>
);

export default Section;

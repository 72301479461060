import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Home from "components/Home/Home";
import ROUTES from "./routes";

export default () => {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route exact path={ROUTES.WEBMAIL} component={() => {
            window.location.href = 'https://webdomain01.dnscpanel.com:2096/';
            return null;
        }} />
      </Switch>
    </Router>
  );
};

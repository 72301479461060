import React, { useState } from "react";

import { Button, Col, DatePicker, Form, Input, message, Row, Select } from "antd";

import Section from "components/section/section.component";

import { ReactComponent as OrcamentoImage } from "assets/images/orcamento.svg";

import "./orcamento.styles.scss";
import { post } from "services/api";
import moment from "moment";

const Orcamento = () => {
  const [name, setName] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [wantedJob, setwantedJob] = useState("");
  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");

  const disabledDate = (current) => (
   current && current < moment().endOf('day')
)

  const OrcamentoImageContainer = () => (
    <Col span={12} xs={24} lg={12}>
      <p className="orcamento-text">Consulte os nossos especialistas.</p>
      <p className="orcamento-text">Peça o seu orçamento!</p>
      <OrcamentoImage className="orcamento-image" />
    </Col>
  );

  const { innerWidth: windowWidth } = window;

  const onFinish = async (values) => {
    const body = {
      nome: name,
      email,
      telefone: cellphone,
      trabalho: wantedJob,
      cidade: city,
      data: date,
      descricao: description,
    };

    message.success('Email enviado com sucesso!');

    await post(
      "padrao-diferente-orcamento",
      body
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Section title="ORÇAMENTO">
      <Row className="orcamento">
        <Col span={12} xs={24} lg={12}>
          <Form className="orcamento-form" size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>

            <Form.Item name="company" rules={[
              {
                required: true,
                message: 'Insira o nome / empresa!',
              },
            ]}>
              <Input placeholder="Nome / Empresa" onChange={(e) => setName(e.target.value)} />
            </Form.Item>

            <Form.Item name="cellphone" rules={[
              {
                required: true,
                message: 'Insira o seu contacto!',
              },
              {
                pattern: /^\d{9}$/,
                message: 'Numero inválido!',
            }
            ]}>
              <Input  placeholder="Telefone / Telémovel" onChange={(e) => setCellphone(e.target.value)} />
            </Form.Item>

            <Form.Item name="email" rules={[
              {
                required: true,
                message: 'Insira o seu email!',
              },
              ]}>
              <Input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>

            <Form.Item name="trabalho" rules={[
              {
                required: true,
                message: 'Selecione o trabalho pretendido!',
              },
              ]}>
              <Select placeholder="Trabalho pretendido" onChange={(e) => setwantedJob(e.toString())}>
                <Select.Option value="Obra nova">Obra nova</Select.Option>
                <Select.Option value="Remodelação">Remodelação</Select.Option>
                <Select.Option value="Projeto">Projeto</Select.Option>
                <Select.Option value="Pintura exterior">Pintura exterior</Select.Option>
                <Select.Option value="Pintura interior">Pintura interior</Select.Option>
                <Select.Option value="Pintura industrial">Pintura industrial</Select.Option>
                <Select.Option value="Impermeabilização">Impermeabilização</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="city" rules={[
              {
                required: true,
                message: 'Insira a cidade!',
              },
              ]}>
              <Input  placeholder="Cidade / Localidade" onChange={(e) => setCity(e.target.value)} />
            </Form.Item>

            <Form.Item name="data" rules={[
              {
                required: true,
                message: 'Selecione a data pretendita!',
              },
              ]}>
              <DatePicker
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                className="orcamento-form-date-picker"
                placeholder="Data pretendida"
                onChange={(e) => setDate(e.format("DD-MM-YYYY").toString())}
              />
            </Form.Item>

            <Form.Item name="description" rules={[
              {
                required: true,
                message: 'Insira uma descrição!',
              },
              ]}>
              <Input.TextArea
                placeholder="Descreva a intervenção que pretende"
                rows={10}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button className="orcamento-form-button" htmlType="submit">
                PEDIR ORÇAMENTO
              </Button>
            </Form.Item>
          </Form>
        </Col>

        {windowWidth > 850 && <OrcamentoImageContainer />}
      </Row>
    </Section>
  );
};

export default Orcamento;

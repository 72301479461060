import React from "react";

import {Map, Marker, Popup, TileLayer} from "react-leaflet";

import "./maps.styles.scss";

const Maps = ({ location, zoomLevel = 17 }) => (
    <Map className="map-container"
          zoomControl={false}
          center={location.coords}
          zoom={zoomLevel}>
      <TileLayer
         detectRetina={true}
         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
         attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors">
      </TileLayer>
      <Marker position={location.coords}>
        <Popup>{location.address}</Popup>
    </Marker>
   </Map>
);

export default Maps;

import React from "react";

import { Col, Row } from "antd";

import { Link } from "react-scroll";

import "./menu.styles.scss";

const Menu = () => (
  <Row className="header-menu">
    <Col span={6}>
      <Link to="quem-somos" activeClass="active" spy={true} smooth={true} duration={500} delay={100}>
        <span className="link-text">QUEM SOMOS</span>
      </Link>
    </Col>
    <Col span={6}>
      <Link to="servicos" activeClass="active" spy={true} smooth={true} duration={500} delay={100}>
        <span className="link-text">SERVIÇOS</span>
      </Link>
    </Col>
    <Col span={6}>
      <Link to="portfolio" activeClass="active" spy={true} smooth={true} duration={500} delay={100}>
        <span className="link-text">PORTFÓLIO</span>
      </Link>
    </Col>
    <Col span={6}>
      <Link to="orcamento" activeClass="active" spy={true} smooth={true} duration={500} delay={100}>
        <span className="link-text">ORÇAMENTO</span>
      </Link>
    </Col>
  </Row>
);

export default Menu;

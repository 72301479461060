import React from "react";

import { Row, Col } from "antd";

import "./header.styles.scss";
import Menu from "components/menu/menu.component";
import Logo from "components/logo/logo.component";
import MenuMobile from "components/menu-mobile/menu-mobile.component";

const { innerWidth: windowWidth } = window;

const Header = () => {
  return (
    <Row className="header" justify="center">
      {windowWidth < 850 ? <MenuMobile /> : <Menu />}
      <Logo />
      <Col span={24}>
        <img alt="header-image" className="header-background" src="/assets/images/header-background.webp" />
      </Col>
      <div className="header-overlay" />
    </Row>
  );
};

export default Header;

import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

import Maps from "components/maps/maps.component";

import countapi from "countapi-js";

import "./footer.styles.scss";
import CountUp from "react-countup";

const Footer = () => {
  const [visits, setVisits] = useState(0);

  useEffect(() => {
    (async () => {
      const result = await countapi.hit("5847192a-8d24-4889-9f67-fd2f23dcc7cp", "visits");
      setVisits(result.value);
    })();
  }, []);

  return (
    <div className="site-footer">
      <Row className="container">
        <Col xs={24} sm={24} md={12}>
          <h6>Contate-nos</h6>
          <div className="footer-icon-container">
            <FontAwesomeIcon icon={faEnvelope} />
            <p className="footer-text">geral@padraodiferente.pt</p>
          </div>
          <div className="footer-icon-container margin-top">
            <FontAwesomeIcon icon={faMobileAlt} />
            <p className="footer-text">936 969 458 / 933 564 687</p>
          </div>
          <div className="footer-icon-container margin-top">
            <FontAwesomeIcon icon={faPhoneAlt} />
            <p className="footer-text">265 718 185</p>
          </div>

          <h6 style={{ paddingTop: 20 }}>Visitas</h6>
          <p className="visits-text">
            <CountUp end={visits} duration={5} />
          </p>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <h6>Localização</h6>
          <Maps
            zoomLevel={15}
            location={{
              address: "Rua dos Pinheirinhos 6, R/C direito, 2910-121, Setúbal",
              coords: [
                38.532100,
                -8.879560,
              ]
            }}
          />
        </Col>

        {/* <Col xs={12} md={6}>
        <h6>Categories</h6>
        <ul className="footer-links">
          <li>
            <a href="http://scanfcode.com/category/c-language/">C</a>
          </li>
          <li>
            <a href="http://scanfcode.com/category/front-end-development/">UI Design</a>
          </li>
          <li>
            <a href="http://scanfcode.com/category/back-end-development/">PHP</a>
          </li>
          <li>
            <a href="http://scanfcode.com/category/java-programming-language/">Java</a>
          </li>
          <li>
            <a href="http://scanfcode.com/category/android/">Android</a>
          </li>
          <li>
            <a href="http://scanfcode.com/category/templates/">Templates</a>
          </li>
        </ul>
      </Col>

      <Col xs={12} md={6}>
        <h6>Quick Links</h6>
        <ul className="footer-links">
          <li>
            <a href="http://scanfcode.com/about/">About Us</a>
          </li>
          <li>
            <a href="http://scanfcode.com/contact/">Contact Us</a>
          </li>
          <li>
            <a href="http://scanfcode.com/contribute-at-scanfcode/">Contribute</a>
          </li>
          <li>
            <a href="http://scanfcode.com/privacy-policy/">Privacy Policy</a>
          </li>
          <li>
            <a href="http://scanfcode.com/sitemap/">Sitemap</a>
          </li>
        </ul>
      </Col> */}
      </Row>
      <hr />

      <Row className="container">
        <Col md={16} sm={12} xs={24}>
          <p className="copyright-text">
            Copyright &copy; 2020 All Rights Reserved by&nbsp;
            <a href="http://www.syntaxdev.com" target="_blank">
              SyntaxDev
            </a>
            .
          </p>
        </Col>

        <Col md={8} sm={12} xs={24}>
          <ul className="social-icons">
            <li>
              <a className="facebook" href="https://www.facebook.com/profile.php?id=100016259470336" target="_blank">
                <FontAwesomeIcon icon={faFacebookF} />
                {/* <i className="fa fa-facebook"></i> */}
              </a>
            </li>
            {/* <li>
            <a className="twitter" href="#">
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a className="dribbble" href="#">
              <i className="fa fa-dribbble"></i>
            </a>
          </li>
          <li>
            <a className="linkedin" href="#">
              <i className="fa fa-linkedin"></i>
            </a>
          </li> */}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;

import React, { useRef } from "react";
import { Row, Carousel, Col } from "antd";
import Section from "components/section/section.component";

import images from "./get-images";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

import "./portfolio.styles.scss";

const Portfolio = () => {

  const carousel = useRef(null);

  const next = () => {
    carousel.current.next();
  }
  const previous = () => {
    carousel.current.prev();
  }

  const {innerWidth: windowWidth} = window;

  return (
    <Section title="PORTFÓLIO">
      <Row className="portfolio">
        {
          windowWidth > 850 && <Col span={4}  className="slider-icon-container">
            <FontAwesomeIcon className="slider-icons margin-right" icon={faArrowCircleLeft} onClick={previous} />
          </Col>
        }

        <Col span={16}>
        <div className="carousel-container">
          <Carousel swipe={false} dots={false} style={{maxHeight: 850}} ref={carousel} >
            {images.map((image) => (
              <div className="beforeAndAfterContainer">
              {image}
              </div>
            ))}
          </Carousel>
        </div>
        </Col>

        {
          windowWidth > 850 &&
        <Col span={4} className="slider-icon-container">
            <FontAwesomeIcon className="slider-icons margin-left" icon={faArrowCircleRight} onClick={next} />
        </Col>
        }

        {
          windowWidth < 850 &&
            <Col span={24} className="slider-icon-container margin-bottom">
              <FontAwesomeIcon className="slider-icons margin-top margin-right" icon={faArrowCircleLeft} onClick={previous} />

              <FontAwesomeIcon className="slider-icons margin-top" icon={faArrowCircleRight} onClick={next} />
            </Col>
        }
      </Row>
    </Section>
  );
};

export default Portfolio;

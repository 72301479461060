import React from "react";

import Section from "components/section/section.component";

import { Col, Row } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHardHat, faPaintRoller, faTint, faCloudShowersHeavy, faIndustry } from "@fortawesome/free-solid-svg-icons";

import "./servicos.styles.scss";

const Servicos = () => (
  <Section title="SERVIÇOS" backgroundColor="#f4f4f4">
    <Row className="servicos">
      <Col span={8} xs={24} lg={8} className="servico-container">
        <FontAwesomeIcon className="servico-icon" icon={faHardHat} />
        <p className="servico-title">Obras e remodelações</p>
        <p className="servico-text">
          Fruto do crescente numero de solicitações a Padrão Diferente executa também obras de remodelação em Moradias,
          Apartamentos e Lojas ajudando assim a dar uma nova vida a habitações e espaços comerciais.
        </p>
      </Col>
      <Col span={8} xs={24} lg={8} className="servico-container padding-top">
        <FontAwesomeIcon className="servico-icon" icon={faPaintRoller} />
        <p className="servico-title">Pintura interior</p>
        <p className="servico-text">
          Como é natural, é importante ter um interior limpo e apresentável que faça as pessoas se sentirem confortáveis
          na sua casa ou nos espaços que frequentam.
        </p>
      </Col>
      <Col span={8} xs={24} lg={8} className="servico-container padding-top">
        <FontAwesomeIcon className="servico-icon" icon={faTint} />
        <p className="servico-title">Pintura exterior</p>
        <p className="servico-text">
          Se o exterior da sua casa ou prédio apresenta algum desgaste, aplicando uma nova pintura pode resolver muitos
          problemas, desde infiltrações indesejadas ao desenvolvimento de fungos. É a forma mais fácil e mais rentável
          para recuperar a sua propriedade.
        </p>
      </Col>
    </Row>

    <Row style={{ width: "100%" }}>
      <Col span={8} xs={24} lg={8} className="servico-container padding-top">
        <FontAwesomeIcon className="servico-icon" icon={faCloudShowersHeavy} />
        <p className="servico-title">Impermeabilização</p>
        <p className="servico-text">
          A coberturas e caleiras dos edifícios são as zonas que mais sofrem com chuva e humidade por essa razão devem
          estar sempre nas melhores condições. Qualquer pequena fisura é o suficiente para provocar danos e prejuísos
          quer na estrutura quer nos bens do seu interior.
        </p>
      </Col>
      <Col span={8} xs={24} lg={8} className="servico-container padding-top">
        <FontAwesomeIcon className="servico-icon" icon={faIndustry} />
        <p className="servico-title">Pintura industrial</p>
        <p className="servico-text">
          A industria é por norma uma atividade que explora ao limite as capacidades dos materiais. A Padrão Diferente
          tem a experiencia necessária para propor e aplicar os melhores produtos para cada situação.
        </p>
      </Col>
    </Row>
  </Section>
);

export default Servicos;

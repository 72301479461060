import React from "react";
import ReactCompareImage from "react-compare-image";

const images = [];

  for (let i = 1; i <= 29; i += 2) {
      images.push(<ReactCompareImage
      key={i}
      leftImage={`/assets/images/portfolio/${i}.JPG`}
      rightImage={`/assets/images/portfolio/${i + 1}.JPG`}
      />);
  }

export default images;

import React from "react";

import { Col, Row } from "antd";

import "./logo.styles.scss";

const Logo = () => (
  <Col span={24} className="logo-container">
    <Row className="center-logo">
      <Col span={24}>
        <img alt="logo" className="logo" src="/logo.png" />
      </Col>
      <Col span={24}>
        <p className="logo-text">PADRÃO DIFERENTE</p>
      </Col>
    </Row>
  </Col>
);

export default Logo;

import React from "react";

import { Col, Row } from "antd";

import "./quem-somos.styles.scss";
import Section from "components/section/section.component";

const QuemSomos = () => (
  <Section title="QUEM SOMOS">
    <Row justify="center" className="quem-somos">
      <Col span={14} xs={24} lg={14}>
        <p className="quem-somos-text">
          Somos uma empresa especializada em Remodelações em todo o tipo de Habitações. Fazemos desde pequenas a grandes
          remodelações, como alvenarias, pinturas, telhados, pavimentos, pladur e divisórias com experiência fruto de
          mais de 15 anos de actividade.
          <br />
          Investimos na nossa formação técnica para estarmos constantemente actualizados de quais os melhores materiais
          existentes no mercado e quais as melhores técnica de aplicação.
          <br />
          Temos como princípio o compromisso com o cliente, tudo faremos ao nosso alcance para cumprir com o combinado,
          utilizando sempre os materiais e a sua aplicação de uma forma correcta.
          <br />
          Valorizamos as nossas pessoas e investimos no seu conhecimento, fazendo com que o Padrão Diferente esteja
          sempre em constante aprimoração para que resulte na melhor experiência para o cliente.
          <br />
          Cumprimos as normas para correcto manusear dos materiais potencialmente poluidores.
          <br />
          Dividimos os nossos projectos em três fases, sendo elas:
          <br />
          <br />
          <b>PLANEAMENTO</b>
          <br />
          Começamos por fazer um planeamento de todas as fases da intervenção. Fazêmo-lo com o cliente, engenheiro,
          designer, ou arquitecto.
          <br />
          <br />
          <b>IMPLEMENTAÇÃO</b>
          <br />
          A fase da construção ou remodelação propriamente dita, é uma fase operacional do planeamento, onde serão de
          uma forma correcta aplicados os materiais ou produtos.
          <br />
          <br />
          <b>ACABAMENTOS</b>
          <br />
          Na fase final de um trabalho é fundamental além da continuada e correcta aplicação, fazer uma verificação ou
          análise de possíveis necessidades ou ajustes a fazer.
        </p>
      </Col>

      <Col span={10} xs={24} lg={10} className="image-container">
        <div className="image-background">
          <img alt="quem-somos" className="quem-somos-image" src="/assets/images/quem_somos.webp" />
        </div>
      </Col>
    </Row>
  </Section>
);

export default QuemSomos;

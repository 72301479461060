import React from "react";

import { Col } from "antd";
import { Link } from "react-scroll";

import { slide as Menu } from "react-burger-menu";

import "./menu-mobile.styles.scss";

const styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "30px",
    height: "20px",
    left: "20px",
    top: "20px",
  },
  bmBurgerBars: {
    background: "#f4f4f4",
  },

  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#f4f4f4",
  },
  bmMenuWrap: {
    position: "fixed",
    top: 0,
    bottom: 0,
    outline: "none",
    border: "none",
  },
  bmMenu: {
    background: "rgba(223, 97, 74, 1)",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
    outline: "none",
    border: "none",
  },
  bmMorphShape: {
    fill: "rgba(223, 97, 74, 1)",
  },
  bmItemList: {
    outline: "none",
    border: "none",
  },

  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

const MenuMobile = () => (
  <div className="menu-container">
    <Menu left styles={styles}>
      <Col span={24} style={{ width: "100%", outline: "none", border: "none", marginTop: 20 }}>
        <Col style={{ marginTop: 20 }}>
          <Link to="quem-somos" activeClass="active" spy={true} smooth={true} duration={500} delay={100}>
            <span className="link-text">QUEM SOMOS</span>
          </Link>
        </Col>
        <Col style={{ marginTop: 20 }}>
          <Link to="servicos" activeClass="active" spy={true} smooth={true} duration={500} delay={100}>
            <span className="link-text">SERVICOS</span>
          </Link>
        </Col>
        <Col style={{ marginTop: 20 }}>
          <Link to="portfolio" activeClass="active" spy={true} smooth={true} duration={500} delay={100}>
            <span className="link-text">PORTFÓLIO</span>
          </Link>
        </Col>
        <Col style={{ marginTop: 20 }}>
          <Link to="orcamento" activeClass="active" spy={true} smooth={true} duration={500} delay={100}>
            <span className="link-text">ORÇAMENTO</span>
          </Link>
        </Col>
      </Col>
    </Menu>
  </div>
);

export default MenuMobile;
